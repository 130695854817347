import { useMemo } from 'react';

import { getParsedBlogPost } from 'helpers/getParsedBlogPost';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { BlogCards } from 'components/Blog/BlogCards';
import { GatsbyLink } from 'components/Links';
import { ContentBlock } from 'components/ContentBlock';

import { Container, Section, HeroContainer, SectionImage } from 'components/Container';
import { ImageCards } from 'components/ImageCard';
import { ResourceHubOverlayContainer } from 'components/ResourceHub';

import ResourceHeroImage from 'images/resource-hub/resource-hero.inline.svg';
import ConnectImage from 'images/resource-hub/connect-to-our-api.inline.svg';

import { ctaCards, resourcesCards } from 'page-data/resourceHub';

import { Box } from 'components/Box';
import type { ContentfulBlogPostRaw } from 'types/blog/blogPost';

const ResourceHub = ({
  pageContext,
}: {
  pageContext: {
    mostRecentPosts: ContentfulBlogPostRaw[];
  };
}) => {
  const blogPosts = useMemo(
    () => pageContext.mostRecentPosts.map(post => getParsedBlogPost(post)),
    [pageContext.mostRecentPosts]
  );

  return (
    <Layout hideFooterCta>
      <Seo />

      <ResourceHubOverlayContainer>
        <HeroContainer background="transparent" wrapMobile="hard">
          <Section>
            <Box paddingBottom={15}>
              <h1>Resource hub</h1>
              <p>
                Get the most out of Sharesight. Get started, stay up-to-date on new features or
                connect with other investors.
              </p>
            </Box>
          </Section>
          <SectionImage image={<ResourceHeroImage />} boxShadow={false} borderRadius={false} />
        </HeroContainer>
      </ResourceHubOverlayContainer>
      <Container padding={1} bottomPadding={15}>
        <ImageCards cards={resourcesCards} />
      </Container>
      <Container>
        <Section>
          <h2>Latest from the blog</h2>
          <BlogCards entries={blogPosts} />
        </Section>
      </Container>
      <Container background="navyBlue">
        <ImageCards cards={ctaCards} gap={15} />
      </Container>
      <ContentBlock
        header="Connect to our API"
        textContent={
          <p>
            Integrate Sharesight features straight into your product. The possibilities are endless
            with our API.
          </p>
        }
        button={
          <GatsbyLink asButton showArrowIcon to="/api-technology-partners/">
            Get started
          </GatsbyLink>
        }
        image={<ConnectImage />}
      />
    </Layout>
  );
};

export default ResourceHub;
