import { StaticImage } from 'gatsby-plugin-image';
import PartnerImage from 'images/resource-hub/cards/become-a-partner.inline.svg';
import AffiliateImage from 'images/resource-hub/cards/become-an-affiliate.inline.svg';

import { LargeCardContent, SmallCardContent } from 'components/ResourceHub';
import type { ImageCardProps } from 'components/ImageCard';

export const resourcesCards: ImageCardProps[] = [
  {
    title: 'Blog',
    content: (
      <SmallCardContent>New features, investing tips, data insights and more.</SmallCardContent>
    ),
    background: 'orangeHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/blog.png"
        alt="Graphic image of a rectangle with three lines"
      />
    ),
    to: '/blog/',
  },
  {
    title: 'Help centre',
    content: <SmallCardContent>Step-by-step guides on how to use Sharesight.</SmallCardContent>,
    background: 'yellowHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/help-centre.png"
        alt="Graphic image of three question marks"
      />
    ),
    to: 'https://help.sharesight.com/',
    localize: false,
  },
  {
    title: 'Get started 101',
    content: <SmallCardContent>4 simple steps to set up your portfolio.</SmallCardContent>,
    background: 'primaryHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/get-started.png"
        alt="Graphic image of three vertical rectangles"
      />
    ),
    to: 'https://help.sharesight.com/getting-started-with-sharesight/',
    localize: false,
  },
  {
    title: 'Forum',
    content: (
      <SmallCardContent>Our vibrant community. Connect with fellow investors.</SmallCardContent>
    ),
    background: 'yellowHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/forum.png"
        alt="Graphic image of two chat icons"
      />
    ),
    to: 'https://community.sharesight.com/',
    localize: false,
  },
  {
    title: 'Webinars',
    content: <SmallCardContent>Demos, trending topics and Sharesight tips.</SmallCardContent>,
    background: 'primaryHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/webinars.png"
        alt="Graphic image of three circles with shadows"
      />
    ),
    to: '/events/',
  },
  {
    title: 'Events',
    content: (
      <SmallCardContent>
        Keynotes and event highlights for investors and professionals.
      </SmallCardContent>
    ),
    background: 'orangeHighlight',
    image: (
      <StaticImage
        src="../images/resource-hub/cards/events.png"
        alt="Graphic image of a location icon"
      />
    ),
    to: '/events/',
  },
];

export const ctaCards: ImageCardProps[] = [
  {
    title: 'Become a partner',
    content: (
      <LargeCardContent>
        From acountants to software providers, expand your product offering by partnering with us.
      </LargeCardContent>
    ),
    to: '/become-a-partner/',
    background: 'navyBlueHighlight',
    image: <PartnerImage />,
    showButton: true,
  },
  {
    title: 'Become an affiliate',
    content: (
      <LargeCardContent>
        Help your followers become better investors and earn up to 50% commission per referral.
      </LargeCardContent>
    ),
    to: '/affiliates/',
    background: 'orangeHighlight',
    image: <AffiliateImage />,
    showButton: true,
  },
];
