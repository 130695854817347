import styled from 'styled-components';

export const SmallCardContent = styled.div`
  font-size: ${props => props.theme.fontSize.sm};
`;

export const LargeCardContent = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;
